import React from 'react'
import PropTypes from 'prop-types'

import {creatureCollection} from "@/constants/creatureList"

import CreatureLinkComponent from './CreatureLinkComponent'

const CreatureLinkContainer = ({ creatureId }) => {
  if (creatureId) {
    const creature = creatureCollection[creatureId]

    return (
      <CreatureLinkComponent {...creature}/>
    )
  }

  return null
}

CreatureLinkContainer.propTypes = {
  creatureId: PropTypes.string,
}

CreatureLinkContainer.defaultProps = {
  creatureId: null,
}

export default CreatureLinkContainer
