import React from 'react'

import Gear from '@/components/Gear'
import GearCatalog from '@/components/GearCatalog'

import {gearCollection} from "@/constants/gearList"
import gearImageCollection from '@/constants/images/gearImageCollection'

import CommonCatalogPageTemplate from '@/templates/CommonCatalogPageTemplate'

const defaultSeoData = {
  title: 'Товары и снаряжение',
  description: 'Каталог всех товаров и снаряжения в Dungeons & Dragons',
  img: null,
}

const GearPageTemplate = (props) => (
  <CommonCatalogPageTemplate
    defaultSeoData={defaultSeoData}
    itemCollection={gearCollection}
    ItemComponent={Gear}
    itemImageCollection={gearImageCollection}
    ListComponent={GearCatalog}
    {...props}
  />
)

export default GearPageTemplate
