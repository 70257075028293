const {
  GEAR_SPIKED_ARMOR,
} = require('./../../gearIdList')

const {
  Battlerager_MTG_CLB_data,
} = require('./../commonImageCollection')

module.exports = {
  [GEAR_SPIKED_ARMOR]: Battlerager_MTG_CLB_data,
}
