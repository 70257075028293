import React from 'react'

import {poisonTypeCollection} from "@/constants/poisonTypeList"

import './PoisonTypeStyles.less'

const PoisonTypeComponent = ({poisonType}) => (
    <span
      className='PoisonType'
      title={poisonTypeCollection[poisonType].description}
    >
        {poisonTypeCollection[poisonType].name}
    </span>
)

export default PoisonTypeComponent
