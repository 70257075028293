import React from 'react'

import columnNameCollection from '@/constants/gearColumnNameCollection'

import './GearInfoTableStyles.less'

const GearInfoTableComponent = ({ list }) => (
  <table className='GearInfoTable'>
    <tbody>
    {
      list.map(
        ({ columnName, content }, i) => (
          <tr
            className='GearInfoTable_row'
            key={`${columnName}_${i}`}
          >
            <th className='GearInfoTable_cell GearInfoTable_cell-head'>
              {columnNameCollection[columnName]}
            </th>
            <td className='GearInfoTable_cell'>
              {content}
            </td>
          </tr>
        ),
      )
    }
    </tbody>
  </table>
)

export default GearInfoTableComponent
