import React from 'react'
import PropTypes from 'prop-types'

import GearTableComponent from './GearTableComponent'

import hideColumnList from "./constants/hideColumnList"

import columnSorter from './utils/columnSorter'

const GearTableContainer = (
  {
    itemList,
    showList,
    ...rest
  },
) => {
  if (showList && itemList && itemList.length) {
    const columnNameList = itemList
      .reduce(
        (list, item) => [
          ...list,
          ...Object
            .keys(item)
            .filter(
              key => !hideColumnList.includes(key) && !list.includes(key)
            )
        ],
        []
      )
      .sort(columnSorter)

    return (
      <GearTableComponent
        itemList={itemList}
        columnNameList={columnNameList}
        {...rest}
      />
    )
  }

  return null
}

GearTableContainer.defaultProps = {
  itemList: [],
}

GearTableContainer.propTypes = {
  itemList: PropTypes.array,
  showList: PropTypes.bool.isRequired,
}

export default GearTableContainer
