const {
  GEAR_MUSKET,
} = require('./../../gearIdList')

const {
  Giff_MTOF_data,
} = require('./../commonImageCollection')

module.exports = {
  [GEAR_MUSKET]: Giff_MTOF_data,
}
