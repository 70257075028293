import React from 'react'
import arrify from 'arrify'

import generateTextLinks from '@/utils/generateTextLinks'

import CreatureLink from '@/components/CreatureLink'
import Markdown from '@/components/Markdown'
import WeaponPropList from "@/components/WeaponPropList"

import './GearDescriptionStyles.less'

export default ({ gear, description }) => {
  if (gear) {
    return (
      <section className='GearDescription'>
        {
          gear.creatureId
            ? <CreatureLink creatureId={gear.creatureId}/>
            : null
        }
        <WeaponPropList gear={gear}/>
        {
          description
            ? (
              <Markdown>
                {generateTextLinks(description.text)}
              </Markdown>
            )
            : null
        }
      </section>
    )
  }

  return null
}
