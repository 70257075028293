import gearTechColumnList from "@/constants/gearTechColumnList";

const addToList = [
  'source',
]

const excludeFromList = [
  'weaponPropList',
]

export default [
  ...gearTechColumnList
    .filter(
      columnName => !excludeFromList.includes(columnName)
    ),
  ...addToList,
]
