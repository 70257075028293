const declint = require('declint-ru')

const {volumeUnitCollection} = require('./../constants/volumeUnitList')

const litresWordList = ['литр', 'литра', 'литров']

module.exports = ({ unitId, count }) => {
  const {name: {singular, dual, plural}, litreCoef} = volumeUnitCollection[unitId]

  const unitWordList = [singular, dual, plural]
  const unitWord = declint(count, unitWordList)

  const litresCount = Math.round(litreCoef * count * 10) / 10
  const litresWord = declint(litresCount, litresWordList)

  return `${count} ${unitWord} (${litresCount} ${litresWord})`.replace(/\./g, ',')
}
