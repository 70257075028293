import React from 'react'
import arrify from 'arrify'

import Creature from '@/components/Creature'
import DescriptionList from "@/components/DescriptionList"
import PageHeader from '@/components/PageHeader'
import SourceInfo from '@/components/SourceInfo'

import GearInfoTable from './components/GearInfoTable'
import GearDescription from './components/GearDescription'
import GearImageList from './components/GearImageList'

import './GearStyles.less'

const GearComponent = (
  {
    gear,
    descriptionFirst,
    descriptionList,
  },
) => (
  <section className='Gear'>
    <section className='Gear_info'>
      <PageHeader {...gear}/>
      <GearDescription
        description={descriptionFirst}
        gear={gear}
      />
      <GearInfoTable gear={gear}/>
      <SourceInfo
        className='Gear_source'
        source={gear.source}
        useFullName
      />
    </section>
    <GearImageList
      className='Gear_imageList'
      gear={gear}
    />
    <DescriptionList
      {...gear}
      description={descriptionList}
    />

    {
      gear.creatureId
        ? arrify(gear.creatureId).map(
          creatureId => <Creature id={creatureId}/>
        )
        : null
    }
  </section>
)

export default GearComponent
