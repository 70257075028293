import React from "react"

import "./WeaponPropStyles.less"

const WeaponPropComponent = ({head, text}) => {
  return (
    <span
      className='WeaponProp'
      title={head}
    >
      {text}
    </span>
  )
}

export default WeaponPropComponent
