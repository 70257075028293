import DEFAULT_FILTER_VALUE from '@/constants/DEFAULT_FILTER_VALUE'
import SEARCH_PROP_NAME from '@/constants/SEARCH_PROP_NAME'
import gearCategoryList from '@/constants/gearCategoryList'
import sortByText from '@/utils/sortByText'
import {
    FILTER_TYPE_INPUT,
    FILTER_TYPE_SELECT,
} from '@/constants/filterTypes'

const formatCategoryAsOption = (
  {
    name: text,
    description,
    id: value,
  },
) => (
  {
    value,
    text,
    title: `${text}\n${description}`,
  }
)

export default [
  {
    label: 'Категория',
    type: FILTER_TYPE_SELECT,
    propName: 'category',
    value: DEFAULT_FILTER_VALUE,
    list: [
      {
        value: DEFAULT_FILTER_VALUE,
        text: 'Любая',
      },
      ...gearCategoryList
        .map(formatCategoryAsOption)
        .sort(sortByText),
    ],
  },
  {
    label: 'Введите название предмета или часть его описания',
    type: FILTER_TYPE_INPUT,
    propName: SEARCH_PROP_NAME,
    value: DEFAULT_FILTER_VALUE,
  },
]
