import React from 'react'

import {paramCollection} from "@/constants/paramList"

import './AbilityCheckStyles.less'

const AbilityCheckComponent = ({type, dc}) => (
    <span
      className='AbilityCheck'
      title={`${paramCollection[type].name}, сложность: ${dc}`}
    >
        {paramCollection[type].shortName} {dc}
    </span>
)

export default AbilityCheckComponent
