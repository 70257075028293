import React from 'react'

import {damageTypeCollection} from "@/constants/damageTypeList"

import './DamageTypeStyles.less'

const DamageTypeComponent = ({ damageType }) => (
    <section className='DamageType'>
        {damageTypeCollection[damageType].name}
    </section>
)

export default DamageTypeComponent
