const {
  GEAR_DOUBLE_BLADED_SCIMITAR,
} = require('./../../gearIdList')

const {
  Double_Bladed_Scimitar_WGtE_data,
} = require('./../commonImageCollection')

module.exports = {
  [GEAR_DOUBLE_BLADED_SCIMITAR]: Double_Bladed_Scimitar_WGtE_data,
}
