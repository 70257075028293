import React from 'react'
import PropTypes from 'prop-types'

import ImageList from '@/components/ImageList'

import gearImageCollection from '@/constants/images/gearImageCollection'

const GearImageListContainer = ({ gear }) => {
  if (gear) {
    const imageList = gearImageCollection[gear.id]

    if (imageList) {
      return (
        <ImageList
          imageList={imageList}
          {...gear}
        />
      )
    }

    return null
  }

  return null
}

GearImageListContainer.propTypes = {
  gear: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
}

export default GearImageListContainer
