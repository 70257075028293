import React from "react"

import {
  weaponPropCollection,
  WEAPON_RANGE,
  WEAPON_RELOAD,
  WEAPON_VERSATILE,
} from "@/constants/weaponPropList"

import formatReload from "@/utils/formatReload"
import formatWeaponRange from "@/utils/formatWeaponRange"
import generateCube from "@/utils/generateCube"

import WeaponPropComponent from "./WeaponPropComponent"

const WeaponPropContainer = ({weaponProp}) => {
  const isObj = typeof weaponProp === 'object'
  const id = isObj
    ? weaponProp.id
    : weaponProp

  const value = isObj
    ? weaponProp.value
    : null

  let valueText = ''

  if (value) {
    if (id === WEAPON_RANGE) {
      valueText = ` (${formatWeaponRange(value)})`
    }

    if (id === WEAPON_RELOAD) {
      valueText = ` (${formatReload(value)})`
    }

    if (id === WEAPON_VERSATILE) {
      valueText = `: ${generateCube(value)}`
    }
  }

  const {name, description} = weaponPropCollection[id]

  const head = `${name}\n${description}`
  const text = `${name}${valueText}`

  return (
    <WeaponPropComponent
      head={head}
      text={text}
    />
  )
}

export default WeaponPropContainer
