import React from 'react'
import { Link } from 'gatsby'

import generateNameStr from '@/utils/generateNameStr'
import generateGearPageUrlById from '@/utils/generateGearPageUrlById'
import formatWeaponRange from '@/utils/formatWeaponRange'
import generateCube from '@/utils/generateCube'
import generateWeightText from '@/utils/generateWeightText'
import generateVolumeText from '@/utils/generateVolumeText'

import AbilityCheck from '@/components/AbilityCheck'
import Ac from '@/components/Ac'
import BooleanMark from '@/components/BooleanMark'
import DamageType from '@/components/DamageType'
import GearCategoryList from '@/components/GearCategoryList'
import Markdown from '@/components/Markdown'
import PoisonType from '@/components/PoisonType'
import Price from '@/components/Price'
import RequirementList from '@/components/RequirementList'
import SourceInfo from '@/components/SourceInfo'
import WeaponPropList from '@/components/WeaponPropList'

export default ({ columnName, item }) => {
  switch (columnName) {
    case 'cost': {
      return <Price price={item[columnName]}/>
    }

    case 'damage': {
      return generateCube(item[columnName])
    }

    case 'poisonType': {
      return <PoisonType poisonType={item[columnName]}/>
    }

    case 'saveThrow': {
      return <AbilityCheck abilityCheck={item[columnName]}/>
    }

    case 'damageType': {
      return <DamageType damageType={item[columnName]}/>
    }

    case 'source': {
      return <SourceInfo source={item[columnName]}/>
    }

    case 'range': {
      return formatWeaponRange(item[columnName])
    }

    case 'acChangeTo': {
      return <Ac item={item}/>
    }

    case 'category': {
      return <GearCategoryList catIdList={item[columnName]}/>
    }

    case 'hidingDisadvantage': {
      return <BooleanMark val={item[columnName]}/>
    }

    case 'requirementList': {
      return <RequirementList list={item[columnName]}/>
    }

    case 'name': {
      const { id } = item
      const { header, title } = generateNameStr(item)

      return (
        <Link
          className='GearTable_link'
          title={title}
          to={generateGearPageUrlById(id)}
        >
          {header}
        </Link>
      )
    }

    case 'weaponPropList': {
      return (
        <WeaponPropList gear={item}/>
      )
    }

    case 'weight': {
      return item[columnName]
        ? generateWeightText(item[columnName], true)
        : ''
    }

    case 'volume': {
      return item[columnName]
        ? generateVolumeText(item[columnName])
        : ''
    }

    default: {
      return (
        <Markdown>
          {String(item[columnName] || '')}
        </Markdown>
      )
    }
  }
}
