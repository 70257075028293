import SEARCH_PROP_NAME from "@/constants/SEARCH_PROP_NAME";

export default [
    SEARCH_PROP_NAME,
    'id',
    'nameEn',
    'nameAlt',
    'nameEnAlt',
    'acUseDexMod',
    'acDexModMax',
    'weaponPropList',
    'creatureId',
    'genderId',
    'description',
    'nameByCase',
    'isUA',
]
