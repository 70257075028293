import React from 'react'

import RequirementItem from './components/RequirementItem'

export default ({ list }) => list.length > 1
  ? (
    <ul>
      {
        list.map(
          (item, i) => (
            <li key={i}>
              <RequirementItem requirement={item}/>
            </li>
          ),
        )
      }
    </ul>
  )
  : <RequirementItem requirement={list[0]}/>
