import React from 'react'
import arrify from 'arrify'

import formatAltName from '@/utils/formatAltName'

import { gearCollection } from '@/constants/gearList'
import { gearCategoryCollection } from '@/constants/gearCategoryList'

import GearComponent from './GearComponent'

const GearContainer = ({ id }) => {
  if (id) {
    const gear = gearCollection[id]

    if (gear) {
      const categoryDescriptionList = gear.category
        ? arrify(gear.category).reduce(
          (list, catId) => {
            const {name, nameAlt, nameEn, nameEnAlt, description} = gearCategoryCollection[catId]
            const nameAltText = formatAltName(nameAlt)
            const nameEnAltText = formatAltName(nameEnAlt)

            return arrify(description).reduce(
              (catDescriptionList, descriptionRaw) => {
                const {header, subHeader, text, source} = typeof descriptionRaw === 'string'
                  ? {
                    header: '',
                    subHeader: '',
                    text: descriptionRaw,
                    source: gear.source,
                  }
                  : descriptionRaw

                return [
                  ...catDescriptionList,
                  {
                    header: `${header || name}${nameAltText}`,
                    subHeader: `${subHeader || nameEn}${nameEnAltText}`,
                    text,
                    source,
                  },
                ]
              },
              list
            )
          },
          []
        )
        : []
      const gearDescriptionList = gear.description
        ? arrify(gear.description).map(
          description => {
            const {header, text, source} = typeof description === 'string'
              ? {
                header: gear.name,
                text: description,
                source: gear.source,
              }
              : description

            return {
              header,
              text,
              source,
            }
          }
        )
        : []
      const descriptionList = [
        ...gearDescriptionList,
        ...categoryDescriptionList,
      ]
        .filter(e => e)
      const descriptionFirst = descriptionList.shift()

      return (
        <GearComponent
          descriptionFirst={descriptionFirst}
          descriptionList={descriptionList}
          gear={gear}
        />
      )
    }
  }

  return null
}

export default GearContainer
