import React from 'react'

import gearList from '@/constants/gearList'
import Catalog from '@/components/Catalog'

import generateGearPageUrlById from '@/utils/generateGearPageUrlById'

import filterListOriginal from './constants/filterList'

import GearTable from './components/GearTable'

const GearCatalogContainer = () => (
    <Catalog
        filterListOriginal={filterListOriginal}
        itemList={gearList}
        itemNameList={gearList}
        pageTitle='Каталог товаров и снаряжения'
        pageUrlGenerator={generateGearPageUrlById}
        Table={GearTable}
    />
)

export default GearCatalogContainer
