import React from 'react'

import WeaponProp from './components/WeaponProp'

import './WeaponPropListStyles.less'

export default ({ gear: { weaponPropList } }) => weaponPropList && weaponPropList.length
  ? (
    <p className='WeaponPropList'>
      {
        weaponPropList.map(
          (weaponProp, i) => (
            <WeaponProp
              weaponProp={weaponProp}
              key={i}
            />
          ),
        )
      }
    </p>
  )
  : null
