import React from 'react'

import formatWeaponRange from '@/utils/formatWeaponRange'
import generateCube from '@/utils/generateCube'
import generateWeightText from '@/utils/generateWeightText'
import generateVolumeText from '@/utils/generateVolumeText'

import BooleanMark from '@/components/BooleanMark'
import AbilityCheck from '@/components/AbilityCheck'
import Ac from '@/components/Ac'
import DamageType from '@/components/DamageType'
import PoisonType from '@/components/PoisonType'
import Price from '@/components/Price'
import RequirementList from '@/components/RequirementList'
import GearCategoryList from '@/components/GearCategoryList'

import gearTechColumnList from "@/constants/gearTechColumnList"

import GearInfoTableComponent from './GearInfoTableComponent'

const GearInfoTableContainer = ({ gear }) => {
  const rowList = Object
    .entries(gear)
    .filter(
      ([propName]) => !gearTechColumnList.includes(propName),
    )
    .map(
      ([propName, value]) => {
        let content = null
        switch (propName) {
          case 'cost': {
            content = <Price price={value}/>
            break
          }

          case 'damage': {
            content = generateCube(value)
            break
          }

          case 'poisonType': {
            content = <PoisonType poisonType={value}/>
            break
          }

          case 'saveThrow': {
            content = <AbilityCheck abilityCheck={value}/>
            break
          }

          case 'damageType': {
            content = <DamageType damageType={value}/>
            break
          }

          case 'range': {
            content = formatWeaponRange(value)
            break
          }

          case 'quantity': {
            content = `${value} шт.`
            break
          }

          case 'length': {
            content = `${value} футов`
            break
          }

          case 'acChangeTo': {
            content = <Ac item={gear}/>
            break
          }

          case 'category': {
            content = <GearCategoryList catIdList={value}/>
            break
          }

          case 'hidingDisadvantage': {
            content = <BooleanMark val={value}/>
            break
          }

          case 'requirementList': {
            content = <RequirementList list={value}/>
            break
          }

          case 'hp':
          case 'acBonus': {
            content = value
            break
          }

          case 'weight': {
            content = value
              ? generateWeightText(value, true)
              : 'незначительная'
            break
          }

          case 'volume': {
            content = value
              ? generateVolumeText(value)
              : ''
            break
          }
        }

        return content
          ? {
            columnName: propName,
            content,
          }
          : null
      },
    )
    .filter(e => e)

  return rowList.length
    ? <GearInfoTableComponent list={rowList}/>
    : null
}

export default GearInfoTableContainer
