const {
  SOURCE_AYAGWnW,
  SOURCE_MTG_AFR,
  SOURCE_MTG_CLB,
  SOURCE_VGTM,
  SOURCE_PHB,
  SOURCE_PotA,
} = require('./../../sourceList')
const {
  GEAR_BOOK,
  GEAR_BREASTPLATE,
  GEAR_BURGLAR_S_PACK,
  GEAR_CALTROPS,
  GEAR_CANDLE,
  GEAR_CHAIN_MAIL,
  GEAR_CHAIN_SHIRT,
  GEAR_CLIMBER_S_KIT,
  GEAR_CLOTHES_FINE,
  GEAR_CLOTHES_TRAVELERS,
  GEAR_COMPONENT_POUCH,
  GEAR_DAGGER,
  GEAR_DUNGEONEER_S_PACK,
  GEAR_EXPLORER_S_PACK,
  GEAR_FLUTE,
  GEAR_GLAIVE,
  GEAR_GRAPPLING_HOOK,
  GEAR_GREATSWORD,
  GEAR_HALBERD,
  GEAR_HALF_PLATE,
  GEAR_HAMMER,
  GEAR_HUNTING_TRAP,
  GEAR_LANTERN_HOODED,
  GEAR_LEATHER_ARMOR,
  GEAR_LONGSWORD,
  GEAR_LUTE,
  GEAR_MILITARY_SADDLE,
  GEAR_NET,
  GEAR_PADDED_ARMOR,
  GEAR_PIKE,
  GEAR_PLATE_ARMOR,
  GEAR_PLAYING_CARD_SET,
  GEAR_POISON_BASIC,
  GEAR_POTION_OF_HEALING,
  GEAR_QUARTERSTAFF,
  GEAR_RING_MAIL,
  GEAR_SCALE_MAIL,
  GEAR_SCIMITAR,
  GEAR_SHIELD,
  GEAR_SHORTSWORD,
  GEAR_SICKLE,
  GEAR_SPELLBOOK,
  GEAR_SPLINT_ARMOR,
  GEAR_STUDDED_LEATHER_ARMOR,
  GEAR_THIEVES_TOOLS,
  GEAR_TORCH,
  GEAR_TRIDENT,
  GEAR_WARHAMMER,
  GEAR_WATERSKIN,
  GEAR_WHETSTONE,
} = require('./../../gearIdList')

const {
  Elturgard_Chain_Shirt_Breastplate_SCAG_data,
  Barbarian_Wulfgar_AYAGWW_data,
  Doss_lute_DMG_data,
  Kenku_Fights_Goblins_AYAGWW_data,
  Tiefling_Scimitar_AYAGWW_data,
  Tiefling_Sword_AYAGWW_data,
  Tortle_AYAGWW_data,
  Ranger_Rashemi_Minsk_AYAGWW_data,
  Potion_of_healing_AYAGWW_data,
  Potion_of_Healing_MTG_AFR_data,
  Filborg_flute_enthralling_performance_MTG_CLB_data,
  Flaming_fist_shield_GAME_BG_3_data,
} = require('./../commonImageCollection')

const Book_PHB_img = require('./../../../images/gears/phb/book.jpg')
const Caltrops_PHB_img = require('./../../../images/gears/phb/caltrops.jpg')
const Candle_PHB_img = require('./../../../images/gears/phb/candle.jpg')
const Dagger_PHB_img = require('./../../../images/gears/phb/dagger.jpg')
const Grappling_hook_PHB_img = require('./../../../images/gears/phb/grappling_hook.jpg')
const Hammer_PHB_img = require('./../../../images/gears/phb/hammer.jpg')
const Lantern_hooded_PHB_img = require('./../../../images/gears/phb/lantern_hooded.jpg')
const Playing_card_set_PHB_img = require('./../../../images/gears/phb/playing_card_set.jpg')
const Sickle_PHB_img = require('./../../../images/gears/phb/sickle.jpg')
const Waterskin_PHB_img = require('./../../../images/gears/phb/waterskin.jpg')
const Whetstone_PHB_img = require('./../../../images/gears/phb/whetstone.jpg')

const Master_Thief_s_Tools_VGTM_img = require('./../../../images/gears/vgtm/master_thiefs_tools.jpg')
const Warlords_Sword_VGTM_img = require('./../../../images/gears/vgtm/warlords_sword.jpg')

const Breastplate_AYAGWW_img = require('./../../../images/gears/ayagww/breastplate.jpg')
const Buckler_AYAGWW_img = require('./../../../images/gears/ayagww/buckler.jpg')
const Burglars_pack_AYAGWW_img = require('./../../../images/gears/ayagww/burglars_pack.jpg')
const Chain_mail_AYAGWW_img = require('./../../../images/gears/ayagww/chain_mail.jpg')
const Chain_shirt_AYAGWW_img = require('./../../../images/gears/ayagww/chain_shirt.jpg')
const Climbers_kit_AYAGWW_img = require('./../../../images/gears/ayagww/climbers_kit.jpg')
const Component_pouch_AYAGWW_img = require('./../../../images/gears/ayagww/component_pouch.jpg')
const Dungeoneers_pack_AYAGWW_img = require('./../../../images/gears/ayagww/dungeoneers_pack.jpg')
const Enarmes_AYAGWW_img = require('./../../../images/gears/ayagww/enarmes.jpg')
const Explorers_pack_AYAGWW_img = require('./../../../images/gears/ayagww/explorers_pack.jpg')
const Fine_clothes_AYAGWW_img = require('./../../../images/gears/ayagww/fine_clothes.jpg')
const Glaive_AYAGWW_img = require('./../../../images/gears/ayagww/glaive.jpg')
const Greatsword_AYAGWW_img = require('./../../../images/gears/ayagww/greatsword.jpg')
const Halberd_AYAGWW_img = require('./../../../images/gears/ayagww/halberd.jpg')
const Halberd_Fighter_AYAGWW_img = require('./../../../images/gears/ayagww/halberd_fighter.jpg')
const Half_plate_AYAGWW_img = require('./../../../images/gears/ayagww/half_plate.jpg')
const Heater_AYAGWW_img = require('./../../../images/gears/ayagww/heater.jpg')
const Hunting_trap_AYAGWW_img = require('./../../../images/gears/ayagww/hunting_trap.jpg')
const Improvised_shield_AYAGWW_img = require('./../../../images/gears/ayagww/improvised_shield.jpg')
const Kite_AYAGWW_img = require('./../../../images/gears/ayagww/kite.jpg')
const Leather_armor_AYAGWW_img = require('./../../../images/gears/ayagww/leather_armor.jpg')
const Longsword_AYAGWW_img = require('./../../../images/gears/ayagww/longsword.jpg')
const Padded_armor_AYAGWW_img = require('./../../../images/gears/ayagww/padded_armor.jpg')
const Pike_AYAGWW_img = require('./../../../images/gears/ayagww/pike.jpg')
const Plate_mail_AYAGWW_img = require('./../../../images/gears/ayagww/plate_mail.jpg')
const Ring_mail_AYAGWW_img = require('./../../../images/gears/ayagww/ring_mail.jpg')
const Scale_mail_AYAGWW_img = require('./../../../images/gears/ayagww/scale_mail.jpg')
const Shortsword_AYAGWW_img = require('./../../../images/gears/ayagww/shortsword.jpg')
const Splint_armor_AYAGWW_img = require('./../../../images/gears/ayagww/splint_armor.jpg')
const Studded_leather_armor_AYAGWW_img = require('./../../../images/gears/ayagww/studded_leather_armor.jpg')
const Travelers_clothes_AYAGWW_img = require('./../../../images/gears/ayagww/travelers_clothes.jpg')
const Umbo_AYAGWW_img = require('./../../../images/gears/ayagww/umbo.jpg')
const Net_and_Trident_AYAGWW_img = require('./../../../images/gears/ayagww/net-and-trident.png')

const Dagger_MTG_AFR_img = require('./../../../images/gears/mtg_afr/dagger-mtg_afr.jpg')
const Torch_MTG_AFR_img = require('./../../../images/gears/mtg_afr/torch-mtg_afr.jpg')
const Spellbook_MTG_AFR_img = require('./../../../images/gears/mtg_afr/spellbook-mtg_afr.jpg')

const Thieves_tools_MTG_CLB_img = require('./../../../images/gears/mtg_clb/thieves-tools.jpg')
const Dungeoneers_pack_MTG_CLB_img = require('./../../../images/gears/mtg_clb/dungeoneers-pack-mtg_clb.webp')
const Military_saddle_MTG_CLB_img = require('./../../../images/gears/mtg_clb/military_saddle_mtg_clb.webp')
const Poison_MTG_CLB_img = require('./../../../images/gears/mtg_clb/poison_mtg_clb.jpg')

const Flute_PotA_img = require('./../../../images/gears/pota/flute.webp')

const Net_and_Trident_AYAGWW = {
  src: Net_and_Trident_AYAGWW_img,
  text: 'Сеть и трезубец',
  source: SOURCE_AYAGWnW,
}

module.exports = {
  [GEAR_BOOK]: {
    src: Book_PHB_img,
    source: SOURCE_PHB,
  },
  [GEAR_BREASTPLATE]: [
    {
      src: Breastplate_AYAGWW_img,
      source: SOURCE_AYAGWnW,
    },
    Elturgard_Chain_Shirt_Breastplate_SCAG_data,
  ],
  [GEAR_BURGLAR_S_PACK]: {
    src: Burglars_pack_AYAGWW_img,
    source: SOURCE_AYAGWnW,
  },
  [GEAR_CALTROPS]: {
    src: Caltrops_PHB_img,
    source: SOURCE_PHB,
  },
  [GEAR_CANDLE]: {
    src: Candle_PHB_img,
    source: SOURCE_PHB,
  },
  [GEAR_CHAIN_MAIL]: {
    src: Chain_mail_AYAGWW_img,
    source: SOURCE_AYAGWnW,
  },
  [GEAR_CHAIN_SHIRT]: [
    {
      src: Chain_shirt_AYAGWW_img,
      source: SOURCE_AYAGWnW,
    },
    Elturgard_Chain_Shirt_Breastplate_SCAG_data,
  ],
  [GEAR_CLIMBER_S_KIT]: {
    src: Climbers_kit_AYAGWW_img,
    source: SOURCE_AYAGWnW,
  },
  [GEAR_CLOTHES_FINE]: {
    src: Fine_clothes_AYAGWW_img,
    source: SOURCE_AYAGWnW,
  },
  [GEAR_CLOTHES_TRAVELERS]: {
    src: Travelers_clothes_AYAGWW_img,
    source: SOURCE_AYAGWnW,
  },
  [GEAR_COMPONENT_POUCH]: {
    src: Component_pouch_AYAGWW_img,
    source: SOURCE_AYAGWnW,
  },
  [GEAR_DAGGER]: [
    {
      src: Dagger_PHB_img,
      source: SOURCE_PHB,
    },
    {
      src: Dagger_MTG_AFR_img,
      source: SOURCE_MTG_AFR,
    },
  ],
  [GEAR_DUNGEONEER_S_PACK]: [
    {
      src: Dungeoneers_pack_AYAGWW_img,
      source: SOURCE_AYAGWnW,
    },
    {
      src: Dungeoneers_pack_MTG_CLB_img,
      source: SOURCE_MTG_CLB,
    },
  ],
  [GEAR_EXPLORER_S_PACK]: {
    src: Explorers_pack_AYAGWW_img,
    source: SOURCE_AYAGWnW
  },
  [GEAR_FLUTE]: [
    {
      src: Flute_PotA_img,
      source: SOURCE_PotA,
    },
    Filborg_flute_enthralling_performance_MTG_CLB_data,
  ],
  [GEAR_GLAIVE]: {
    src: Glaive_AYAGWW_img,
    source: SOURCE_AYAGWnW,
  },
  [GEAR_GRAPPLING_HOOK]: {
    src: Grappling_hook_PHB_img,
    source: SOURCE_PHB,
  },
  [GEAR_GREATSWORD]: [
    {
      src: Greatsword_AYAGWW_img,
      source: SOURCE_AYAGWnW,
    },
    {
      src: Warlords_Sword_VGTM_img,
      text: 'Двуручный меч военачальника',
      source: SOURCE_VGTM,
    },
  ],
  [GEAR_HALBERD]: [
    {
      src: Halberd_AYAGWW_img,
      source: SOURCE_AYAGWnW,
    },
    {
      src: Halberd_Fighter_AYAGWW_img,
      text: 'Воины с алебардами среди гоблинов',
      source: SOURCE_AYAGWnW,
    },
  ],
  [GEAR_HALF_PLATE]: [
    {
      src: Half_plate_AYAGWW_img,
      source: SOURCE_AYAGWnW,
    },
    Tiefling_Sword_AYAGWW_data,
  ],
  [GEAR_HAMMER]: {
    src: Hammer_PHB_img,
    source: SOURCE_PHB,
  },
  [GEAR_HUNTING_TRAP]: {
    src: Hunting_trap_AYAGWW_img,
    source: SOURCE_AYAGWnW,
  },
  [GEAR_LANTERN_HOODED]: {
    src: Lantern_hooded_PHB_img,
    source: SOURCE_PHB,
  },
  [GEAR_LEATHER_ARMOR]: {
    src: Leather_armor_AYAGWW_img,
    source: SOURCE_AYAGWnW,
  },
  [GEAR_LONGSWORD]: [
    {
      src: Longsword_AYAGWW_img,
      source: SOURCE_AYAGWnW,
    },
    Ranger_Rashemi_Minsk_AYAGWW_data,
  ],
  [GEAR_LUTE]: [
    Doss_lute_DMG_data,
    Tortle_AYAGWW_data,
  ],
  [GEAR_MILITARY_SADDLE]: {
    src: Military_saddle_MTG_CLB_img,
    source: SOURCE_MTG_CLB,
  },
  [GEAR_NET]: Net_and_Trident_AYAGWW,
  [GEAR_QUARTERSTAFF]: Kenku_Fights_Goblins_AYAGWW_data,
  [GEAR_PADDED_ARMOR]: {
    src: Padded_armor_AYAGWW_img,
    source: SOURCE_AYAGWnW,
  },
  [GEAR_PIKE]: {
    src: Pike_AYAGWW_img,
    source: SOURCE_AYAGWnW,
  },
  [GEAR_PLATE_ARMOR]: {
    src: Plate_mail_AYAGWW_img,
    source: SOURCE_AYAGWnW,
  },
  [GEAR_PLAYING_CARD_SET]: {
    src: Playing_card_set_PHB_img,
    source: SOURCE_PHB,
  },
  [GEAR_POISON_BASIC]: {
    src: Poison_MTG_CLB_img,
    text: 'Отравленный клинок',
    source: SOURCE_MTG_CLB,
  },
  [GEAR_POTION_OF_HEALING]: [
    Potion_of_healing_AYAGWW_data,
    Potion_of_Healing_MTG_AFR_data,
  ],
  [GEAR_RING_MAIL]: {
    src: Ring_mail_AYAGWW_img,
    source: SOURCE_AYAGWnW,
  },
  [GEAR_SCALE_MAIL]: [
    {
      src: Scale_mail_AYAGWW_img,
      source: SOURCE_AYAGWnW,
    },
    Ranger_Rashemi_Minsk_AYAGWW_data,
  ],
  [GEAR_SCIMITAR]: Tiefling_Scimitar_AYAGWW_data,
  [GEAR_SHORTSWORD]: {
    src: Shortsword_AYAGWW_img,
    source: SOURCE_AYAGWnW,
  },
  [GEAR_SICKLE]: {
    src: Sickle_PHB_img,
    source: SOURCE_PHB,
  },
  [GEAR_SPELLBOOK]: {
    src: Spellbook_MTG_AFR_img,
    source: SOURCE_MTG_AFR,
  },
  [GEAR_SPLINT_ARMOR]: {
    src: Splint_armor_AYAGWW_img,
    source: SOURCE_AYAGWnW,
  },
  [GEAR_STUDDED_LEATHER_ARMOR]: {
    src: Studded_leather_armor_AYAGWW_img,
    source: SOURCE_AYAGWnW,
  },
  [GEAR_SHIELD]: [
    {
      src: Umbo_AYAGWW_img,
      text: 'Умбон',
      source: SOURCE_AYAGWnW,
    },
    {
      src: Buckler_AYAGWW_img,
      text: 'Баклер',
      source: SOURCE_AYAGWnW,
    },
    {
      src: Enarmes_AYAGWW_img,
      text: 'Энармс',
      source: SOURCE_AYAGWnW,
    },
    {
      src: Kite_AYAGWW_img,
      text: 'Каплевидный щит',
      source: SOURCE_AYAGWnW,
    },
    {
      src: Heater_AYAGWW_img,
      text: 'Экю',
      source: SOURCE_AYAGWnW,
    },
    {
      src: Improvised_shield_AYAGWW_img,
      text: 'Импровизированные щиты',
      source: SOURCE_AYAGWnW,
    },
    Flaming_fist_shield_GAME_BG_3_data,
  ],
  [GEAR_THIEVES_TOOLS]: [
    {
      src: Master_Thief_s_Tools_VGTM_img,
      text: 'Воровские инструменты мастера вора',
      source: SOURCE_VGTM,
    },
    {
      src: Thieves_tools_MTG_CLB_img,
      source: SOURCE_MTG_CLB,
    },
  ],
  [GEAR_TORCH]: {
    src: Torch_MTG_AFR_img,
    source: SOURCE_MTG_AFR,
  },
  [GEAR_TRIDENT]: Net_and_Trident_AYAGWW,
  [GEAR_WARHAMMER]: Barbarian_Wulfgar_AYAGWW_data,
  [GEAR_WATERSKIN]: {
    src: Waterskin_PHB_img,
    source: SOURCE_PHB,
  },
  [GEAR_WHETSTONE]: {
    src: Whetstone_PHB_img,
    source: SOURCE_PHB,
  },
}
