import React from 'react'

import {paramCollection} from "@/constants/paramList"

function RequirementItemComponent(
  {
    requirement,
    requirement: {
      type,
      paramType,
      min,
    },
  }
) {
  let text = ''

  switch (type) {
    case 'param':
      const paramName = paramCollection[paramType].shortName
      text = `${paramName} ${min}`
      break

    default:
      text = JSON.stringify(requirement)
  }

  return (
    <p>{text}</p>
  )
}

export default RequirementItemComponent
