import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import SourceInfo from "@/components/SourceInfo"

import generateCreaturePageUrlById from "@/utils/generateCreaturePageUrlById"

const CreatureLinkComponent = ({name, id, ...rest}) => {
  const url = generateCreaturePageUrlById(id)

  return (
    <p>
      Статблок: <Link to={url}>
        {name}
      </Link> (
      <i>
        <SourceInfo {...rest}/>
      </i>
      )
    </p>
  )
}

CreatureLinkComponent.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
}

export default CreatureLinkComponent
