import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import columnNameCollection from '@/constants/gearColumnNameCollection'

import GearTableCell from './components/GearTableCell'

import columnNameAlignRightList from './constants/columnNameAlignRightList'

import checkIfNarrow from './utils/checkIfNarrow'

import './GearTableStyles.less'

const GearTableComponent = (
  {
    itemList,
    columnNameList,
  },
) => (
  <table className='GearTable'>
    <thead className='GearTable_head'>
    <tr className='GearTable_row'>
      {
        columnNameList.map(
          columnName => (
            <th
              key={columnName}
              className={classNames(
                'GearTable_cell',
                'GearTable_cell-head',
                {
                  'GearTable_cell-narrow': checkIfNarrow(columnName),
                  'GearTable_cell-right': columnNameAlignRightList.includes(columnName),
                }
              )}
            >
              {columnNameCollection[columnName] ||columnName}
            </th>
          )
        )
      }
    </tr>
    </thead>
    <tbody className='GearTable_body'>
    {
      itemList.map(
        item => (
          <tr className='GearTable_row'>
            {
              columnNameList.map(
                columnName => (
                  <td
                    key={columnName}
                    className={classNames(
                      'GearTable_cell',
                      {
                        'GearTable_cell-right': columnNameAlignRightList.includes(columnName),
                      }
                    )}
                  >
                    <GearTableCell
                      columnName={columnName}
                      item={item}
                    />
                  </td>
                )
              )
            }
          </tr>
        )
      )
    }
    </tbody>
  </table>
)

GearTableComponent.defaultProps = {
  itemCollection: {},
}

GearTableComponent.propTypes = {
  itemCollection: PropTypes.object,
  itemCollectionKeysList: PropTypes.array.isRequired,
  pageUrlGenerator: PropTypes.func.isRequired,
}

export default GearTableComponent
